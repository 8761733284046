* {
    box-sizing: border-box;
}

/* HTML and Body */

html, body {
    margin: 0;
    padding: 0;
}

body {
    display: flex;
    justify-content: space-around;
    height: 100vh;
    max-width: 100vw;
    margin: 0;
    border-radius: 5px;
    background-color: #0B2434;
    padding: 5px;
    font-family: 'Karla', sans-serif;
}

/* Main on Game, Spreadsheet, Rules, How to Play */

/* Mobile */
main {
    background-color: #F5F5F5;
    height: 100vh;
    max-width: 100vw;
    padding: 5px;
    display: flex;
    flex-direction: column; /* Alternative - row */
    justify-content: space-around; /* Align flex items horizontally */
    align-items: center; /* Align flex items vertically */
    gap: 5px; 
}
/* Tablet */
@media only screen and (min-width: 600px) {
    main {
        background-color: #F5F5F5;
        height: 600px;
        max-width: 800px;
        padding: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
}
/* Desktop */
@media only screen and (min-width: 768px) {
    main {
        background-color: #F5F5F5;
        display: flex;
        flex-direction: column;
        height: 600px;
        max-width: 1000px;
        padding: 5px;
        justify-content: space-around;
        align-items: center;
    }
}

/* Scoresheet */
/* Mobile */
.scoresheet {
    max-width: 100vw;
}
/* Tablet */
@media only screen and (min-width: 600px) {
    .scoresheet {
        min-width: 500px;
    }
}
/* Desktop */
@media only screen and (min-width: 768px) {
    .scoresheet {
        min-width: 670px;
    }
}

/* Rules Page */

    .rules {
        height: 450px;
        overflow: scroll;
        padding: 10px;
        max-width: 670px;
    }


    .rules-h3 {
        font-size: 1.2rem;
        font-weight: bold;
    }

    .rules.thick {
        font-weight: bold;
    }


    /* Mobile */
.rules-container {
    display: flex;
    flex-direction: column;
    flex-basis: content;
    gap: 1px;
    align-content: flex-start;
    line-height: 80%;
}

    /* Tablet */
    @media only screen and (min-width: 600px) {
    .rules-container {
        display: grid;
        grid-template: auto / 1fr 3fr 3fr;
        gap: 1px;
        line-height: 80%;
    }
}

    /* Desktop */
    @media only screen and (min-width: 768px) {
        .rules-container {
            display: grid;
            grid-template: auto / 1fr 3fr 3fr;
            gap: 1px;
            line-height: 80%;
        }
    }


    /* How to Play Page */

    .howtoplay {
        height: 450px;
        overflow: scroll;
        max-width: 670px;
    }

    /* Navigation bar */

    /* Mobile */
    header {
        background-color: #a8a467;
        max-width: 100vw;
        height: 60px;
        display: flex;
        align-items: center;
        padding-block: 1px;
    }
    /* Tablet */
    @media only screen and (min-width: 600px) {
        header {
            background-color: #a8a467;
            max-width: 100vw;
            height: 80px;
            display: flex;
            align-items: center;
            padding-block: 5px;
        }
    }
    /* Desktop */
    @media only screen and (min-width: 768px) {
        header {
            background-color: #a8a467;
            max-width: 1030px;
            height: 80px;
            display: flex;
            align-items: center;
            padding-block: 5px;
        }
    }

    header .site-logo {
        color: black;
        margin-right: auto;
        text-transform: uppercase;
        font-weight: 900;
        font-size: 25px;
    }

    header a {
        text-decoration: none;
        color: #4D4D4D;
        font-weight: 600;
        padding: 5px 20px;
    }

    header a:hover {
        color: #161616;
        text-decoration: underline;
    }

    .link-button {
        display: inline-block;
        text-align: center;
        text-decoration: none;
        border: none;
        padding: .75rem 1.375rem;
        color: initial;
        font-weight: 700;
        border-radius: 5px;
        cursor: pointer;
        transition: transform .1s ease-in-out;
    }

        .link-button:hover {
            transform: translate(1px, 1px);
        }

    /* Title on Game Page */

    /* Mobile */
    .title {
        font-size: 1.5em;
        padding: 0.5em;
        margin: 0;
        margin-bottom: 1px;
    }
    /* Tablet */
    @media only screen and (min-width: 600px) {
        .title {
            font-size: 2.0em;
            padding: 10px;
            margin: 0;
            margin-bottom: 5px;
        }
    }
    /* Desktop */
    @media only screen and (min-width: 768px) {
        .title {
            font-size: 2.0em;
            padding: 10px;
            margin: 0;
            margin-bottom: 5px;
        }
    }

    /*  Totals on Game component */

    /* Mobile */
.total-container {
    font-size: 1.0rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 3px;
    margin-bottom: 1px;
    margin-right: 25px;
}
    /* Tablet */
    @media only screen and (min-width: 600px) {
        .total-container {
            font-size: 1.5rem;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(2, 1fr);
            gap: 15px;
            margin-bottom: 5px;
        }
    }
    /* Desktop */
    @media only screen and (min-width: 768px) {
        .total-container {
            font-size: 1.5rem;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(2, 1fr);
            gap: 15px;
            margin-bottom: 5px;
        }
    }

    /* Mobile */
    .total-label {
        height: 40px;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    /* Tablet */
    @media only screen and (min-width: 600px) {
        .total-label {
            height: 50px;
            width: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    /* Desktop */
    @media only screen and (min-width: 768px) {
        .total-label {
            height: 50px;
            width: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    /* Mobile */
    .total-value {
        height: 2.0rem;
        width: 4.0rem;
        background-color: #5035FF;
        color: white;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    /* Tablet */
    @media only screen and (min-width: 600px) {
        .total-value {
            height: 50px;
            width: 75px;
            background-color: #5035FF;
            color: white;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    /* Desktop */
    @media only screen and (min-width: 768px) {
        .total-value {
            height: 50px;
            width: 75px;
            background-color: #5035FF;
            color: white;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    /* Mobile */
.total-score {
    height: 2.0rem;
    width: 4.0rem;
    background-color: #63abbf;
    color: white;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
    /* Tablet */
    @media only screen and (min-width: 600px) {
        .total-score {
            height: 50px;
            width: 75px;
            background-color: #63abbf;
            color: white;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    /* Desktop */
    @media only screen and (min-width: 768px) {
        .total-score {
            height: 50px;
            width: 75px;
            background-color: #63abbf;
            color: black;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    /* Dice on Game Page */
    .dice-container {
        display: grid;
        grid-template-rows: repeat(2, auto);
        grid-template-columns: repeat(4, 1fr);
        gap: 15px;
        margin-bottom: 2px;
    }
    /* Tablet */
    @media only screen and (min-width: 600px) {
        .dice-container {
            display: grid;
            grid-template: auto auto / repeat(7, 1fr);
            gap: 20px;
            margin-bottom: 5px;
        }
    }
    /* Desktop */
    @media only screen and (min-width: 768px) {
        .dice-container {
            display: grid;
            grid-template: auto auto / repeat(7, 1fr);
            gap: 20px;
            margin-bottom: 5px;
        }
    }

    /* Die component */

    .die-face {
        height: 50px;
        width: 50px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    /* Mobile */
    .die-num {
        font-size: 1rem;
    }
    /* Tablet */
    @media only screen and (min-width: 600px) {
        .die-num {
            font-size: 2rem;
        }
    }
    /* Desktop */
    @media only screen and (min-width: 768px) {
        .die-num {
            font-size: 2rem;
        }
    }

    /* Buttons on Game component*/

    /* Mobile */
    .button-container {
        display: grid;
        grid-template: auto auto / repeat(3, 1fr);
        gap: 5px;
        margin-bottom: 1px;
    }
    /* Tablet */
    @media only screen and (min-width: 600px) {
        .button-container {
            display: grid;
            grid-template: auto auto / repeat(3, 1fr);
            gap: 20px;
            margin-bottom: 5px;
        }
    }
    /* Desktop */
    @media only screen and (min-width: 768px) {
        .button-container {
            display: grid;
            grid-template: auto auto / repeat(3, 1fr);
            gap: 20px;
            margin-bottom: 5px;
        }
    }

    /* Mobile */
    .button-container-end {
        display: grid;
        grid-template: auto auto / repeat(1, 1fr);
        gap: 5px;
        margin-bottom: 1px;
    }
    /* Tablet */
    @media only screen and (min-width: 600px) {
        .button-container-end {
            display: grid;
            grid-template: auto auto / repeat(1, 1fr);
            gap: 20px;
            margin-bottom: 5px;
        }
    }
    /* Desktop */
    @media only screen and (min-width: 768px) {
        .button-container-end {
            display: grid;
            grid-template: auto auto / repeat(1, 1fr);
            gap: 20px;
            margin-bottom: 5px;
        }
    }

    /* Mobile */
    .button-format {
        height: 40px;
        width: 100px;
        border: none;
        border-radius: 6px;
        background-color: #5035FF;
        color: white;
        font-size: 0.8rem;
        font-family: 'Karla', sans-serif;
        cursor: pointer;
    }
    /* Tablet */
    @media only screen and (min-width: 600px) {
        .button-format {
            height: 50px;
            width: 150px;
            border: none;
            border-radius: 6px;
            background-color: #5035FF;
            color: white;
            font-size: 1.2rem;
            font-family: 'Karla', sans-serif;
            cursor: pointer;
        }
    }
    /* Desktop */
    @media only screen and (min-width: 768px) {
        .button-format {
            height: 50px;
            width: 150px;
            border: none;
            border-radius: 6px;
            background-color: #5035FF;
            color: white;
            font-size: 1.2rem;
            font-family: 'Karla', sans-serif;
            cursor: pointer;
        }
    }

    .button-format:focus {
        outline: none;
    }

    .button-format:active {
        box-shadow: inset 5px 5px 10px -3px rgba(0, 0, 0, 0.7);
    }

    table {
        display: grid;
        padding: 20px;
        border-collapse: collapse;
        min-width: 100%;
        grid-template-columns: minmax(150px, 1fr) minmax(50px, 1fr);
    }

    thead,
    tbody,
    tr {
        display: contents;
    }

    th,
    td {
        padding: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        justify-content: center;
        align-items: center;
    }

    .td-button-format {
        border: none;
        border-radius: 6px;
        font-family: 'Karla', sans-serif;
        cursor: pointer;
    }

        .td-button-format:focus {
            outline: none;
        }

        .td-button-format:active {
            box-shadow: inset 5px 5px 10px -3px rgba(0, 0, 0, 0.7);
        }

    th {
        height: 40px;
        position: sticky;
        top: 0;
        background: #6c7ae0;
        text-align: left;
        font-weight: normal;
        font-size: 1.1rem;
        color: white;
    }

        th:last-child {
            border: 0;
        }

    td {
        height: 30px;
        padding-top: 5px;
        padding-bottom: 5px;
        color: #808080;
    }

    tr:nth-child(even) td {
        background: #ece8fa;
    }

/* Ad Panels*/
.adContainer {
    display: flex;
    height: 100vh;
}

/* Tablet */
@media only screen and (min-width: 600px) {
    .adContainer {
        display: grid;
        grid-template: auto / 1fr 5fr 1fr;
        max-width: 1030px;
        height: 100vh;
    }
}

/* Desktop */
@media only screen and (min-width: 768px) {
    .adContainer {
        display: grid;
        grid-template: auto / 1fr 5fr 1fr;
        max-width: 1030px;
        height: 100vh;
    }
}

    .panel {
        background-color: #f0f0f0;
        height: 600px;
        padding: 10px;
    }

    .left-panel {
        border-right: 1px solid #ccc;
    }

    .right-panel {
        border-left: 1px solid #ccc;
    }
